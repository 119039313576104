<template>
  <b-card>
    <b-row>
      <b-col sm="8" class="pb-1">
        <b-button
          variant="outline-primary"
          :to="{ name: 'organization-role-add' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ $t('message.Add_Role') }}
        </b-button>
      </b-col>
      <b-col sm="4">
        <b-form-group>
          <b-form-input
            :placeholder="$t('message.search')"
            type="search"
            class="d-inline-block"
            v-model="searchQuery"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          ref="refRoleListTable"
          :items="roles"
          hover
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty=""
          :empty-text="isLoading ? $t('message.loading') : $t('message.no_records')"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
        >

          <!--template #cell(responsibilities)="data">
            <b-badge
              v-for="(item,index) in data.item.responsibilities.slice(0,2)"
              :key="index"
              class="ml-1 mb-1"
              variant="light-primary"
            >
              {{ truncateWords(item) }}
            </b-badge>

            <feather-icon
              v-if="data.item.responsibilities.length > 2"
              badge-classes="custom_badge_settings"
              :badge="`+${data.item.responsibilities.length - 2}`"
              icon=""
              size="1"
            />

          </template>

          <template #cell(qualifications)="data">

            <b-badge
              v-for="(item,index) in data.item.qualifications.slice(0,2)"
              :key="index"
              class="ml-1 mb-1"
              variant="light-danger"
            >
              {{ truncateWords(item) }}
            </b-badge>
            <feather-icon
              v-if="data.item.qualifications.length > 2"
              badge-classes="custom_badge_settings"
              :badge="`+${data.item.qualifications.length - 2}`"
              icon=""
              size="1"
            />

          </template-->

          <template #cell(actions)="data">
            <div class="flex flex-column">
              <!-- <ViewButton /> -->
              <EditButton @clicked="router.push({name:'organization-role-edit', params: { id: data.item._id }})" />
              <DeleteButton v-b-modal="`modal ${data.item._id}`"/>
              <b-modal
                :id="`modal ${data.item._id}`"
                centered
                no-close-on-backdrop
                modal-class="modal-danger"
                ok-variant="danger"
                cancel-variant="outline-secondary"
                :title="$t('message.confirm_action')"
                :ok-title="$t('message.delete')"
                :cancel-title="$t('message.cancel')"
                @ok="deleteRole(data.item._id, deletedSuccess)"
              >
                {{ $t('message.confirm_delete_role') }}
              </b-modal>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
        class="d-flex justify-content-between flex-wrap"
      >
        <div class="mb-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t('message.per_page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="[5,10,15,20,25,30,40,50,100]"
              class="w-50"
            />
          </b-form-group>
        </div>

        <div class="mb-1">
          <span class="text-muted">{{ showingMessage }}</span>
        </div>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalRoles"
          :per-page="perPage"
          first-number=""
          last-number=""
          prev-class="prev-item"
          next-class="next-item"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BBadge, BPagination, BTable, BModal, VBModal } from 'bootstrap-vue'

import { onMounted, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import EditButton from '@/views/components/Shared/Buttons/EditButton'
// import ViewButton from '@/views/components/Shared/Buttons/ViewButton'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton'

import useRoleList from './useRoleList'

import roleStoreModule from '../roleStoreModule'
import useNotifications from "@/composables/useNotifications";
import i18n from '@/libs/i18n'

export default {
  components: {
    BTable,
    BBadge,
    BPagination,
    EditButton,
    // ViewButton,
    DeleteButton,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      items: [],
    }
  },
  setup() {
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const ORGANIZATION_ROLE_STORE_MODULE_NAME = 'organization-role'
    const { router } = useRouter()

    const {
      index,
      showingMessage,
      tableColumns,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      totalRoles,
      dataMeta,
      roles,
      refRoleListTable,
      currentPage,
      perPage,
      deleteRole,
      isLoading,
    } = useRoleList()

    // Register module
    if (!store.hasModule(ORGANIZATION_ROLE_STORE_MODULE_NAME)) {
      store.registerModule(
        ORGANIZATION_ROLE_STORE_MODULE_NAME,
        roleStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_ROLE_STORE_MODULE_NAME)) store.unregisterModule(ORGANIZATION_ROLE_STORE_MODULE_NAME)
    })

    onMounted(() => {
      index();
    });

    const deletedSuccess = (success) => {
      if (success) {
        index();
        showSuccessMessage(i18n.t('message.role_remove'));
      } else {
        showErrorMessage(i18n.t('message.something_went_wrong'));
      }
    };

    return {
      index,
      showingMessage,
      router,
      tableColumns,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRoleListTable,
      roles,
      totalRoles,
      dataMeta,
      currentPage,
      perPage,
      deleteRole,
      deletedSuccess,
      isLoading,
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.process-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.form-group {
  label {
    font-size: 0.85rem;
    font-weight: 400;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
